<template>
    <!-- ======= Values Section ======= -->
    <section id="whatwedo" class="values">

    <div class="container" data-aos="fade-up">

        <header class="section-header">
        <!-- <h2>Our Values</h2> -->
        <h3>Step into your dream home with confidence.</h3>
        <h4>Buying your dream home should be an exciting adventure, not a daunting task.</h4>
        </header>

        <div class="row">

        <div class="col-lg-4" data-aos="fade-up" data-aos-delay="200">
            <div class="box">
            <img src="@/assets/img/frame-1.png" class="img-fluid" alt="">
            <h3>Simplify due diligence.</h3>
            <p>Heya's AI insights simplify disclosure documents, highlighting what matters for informed decisions.</p>
            </div>
        </div>

        <div class="col-lg-4 mt-4 mt-lg-0" data-aos="fade-up" data-aos-delay="400">
            <div class="box">
            <img src="@/assets/img/frame-2.png" class="img-fluid" alt="">
            <h3>Offer Effortlessly.</h3>
            <p>Ready to take the leap? Heya AI helps you craft a competitive offer—quickly and easily.</p>
            </div>
        </div>

        <div class="col-lg-4 mt-4 mt-lg-0" data-aos="fade-up" data-aos-delay="600">
            <div class="box">
            <img src="@/assets/img/frame-3.png" class="img-fluid" alt="">
            <h3>Close smoothly.</h3>
            <p>
Heya guides you through home-buying's final steps with clear, step-by-step instructions to ensure a smooth closing.</p>
            </div>
        </div>

        </div>

    </div>

    </section><!-- End Values Section -->
</template>