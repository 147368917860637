<template>
    <!-- ======= F.A.Q Section ======= -->
      <section id="listingFaq" class="faq">

        <div class="container" data-aos="fade-up">

          <header class="section-header">
            <!-- <h2>F.A.Q</h2> -->
            <h3>What buyers are asking</h3>
          </header>

          <div class="row">
            <div class="col-lg-6">
              <!-- F.A.Q List 1-->
              <div class="accordion accordion-flush" id="faqlist1">
                <div class="accordion-item" v-for="evenIndex in [...qAndAs[listingId]].map((_,i)=>i).filter(i=>i%2===0)" :key="evenIndex">
                  <h2 class="accordion-header">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq-content-1">
                      {{qAndAs[listingId][evenIndex].question}}
                    </button>
                  </h2>
                  <div id="faq-content-1" class="accordion-collapse collapse" data-bs-parent="#faqlist1">
                    <div class="accordion-body">
                      {{qAndAs[listingId][evenIndex].answer}}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            

            <div class="col-lg-6">

              <!-- F.A.Q List 2-->
              <div class="accordion accordion-flush" id="faqlist2">

                <div class="accordion-item" v-for="oddIndex in [...qAndAs[listingId]].map((_,i)=>i).filter(i=>i%2===1)" :key="oddIndex">
                  <h2 class="accordion-header">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq2-content-1">
                      {{qAndAs[listingId][oddIndex].question}}
                    </button>
                  </h2>
                  <div id="faq2-content-1" class="accordion-collapse collapse" data-bs-parent="#faqlist2">
                    <div class="accordion-body">
                      {{ qAndAs[listingId][oddIndex].answer }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section><!-- End F.A.Q Section -->
</template>
<script>
export default ({
  name: 'ListingFAQ',
  props: ['listingId'],
  data() {
    return {
      qAndAs: {
        "209_castenada": [
          {
          question: 'When is the offer deadline?',
          answer: 'The seller will evaluate offers on a first-come, first-served basis, as there is no set deadline for submissions.',
          },
          {
          question: 'Why Is The Seller Leaving?',
          answer: 'This property was acquired with investment objectives in mind. The owner has made comprehensive updates and is now offering it for sale to realize the investment returns.',
          },
          {
          question: 'What Is The Neighborhood Like?',
          answer: 'Milbrae, CA, stands out as a prime residential neighborhood, known for its excellent public schools and a strong sense of community. Its close proximity to both San Francisco and Silicon Valley makes it an ideal location for professionals seeking a serene suburban lifestyle while still having easy access to major business hubs. The town boasts lush parks, a range of local amenities, and a vibrant downtown area, making it a highly desirable place to live for families and individuals alike.',
          },
          {
          question: 'Is This Home A Stigmatized Property?',
          answer: 'There are no disclosures regarding the home being a stigmatized property, including any history of events such as murder, suicide, or alleged supernatural occurrences.',
          },
          {
          question: 'Have There Been Any Additions Or Renovations?',
          answer: 'Yes, the owner has made comprehensive updates to the home.',
          },
          {
          question: 'How Old Is The Roof?',
          answer: 'The exact age of the roof is not provided in the context given. However, the roof is a metal tile system showing excessive wear and tear, and the estimated remaining serviceable life is 15-20 years, implying that it may be around 20-25 years old, considering the intended life of a metal tile roof is 40+ years. For a precise age, it is recommended to consult the seller or obtain a detailed roof inspection report.',
          }
        ],
        "1_mandalay": [
            {
            question: 'When is the offer deadline?',
            answer: 'The seller will evaluate offers on a first-come, first-served basis, as there is no set deadline for submissions.',
            },
            {
            question: 'What is the HOA fee per month?',
            answer: 'This unit has a $707 monthly HOA fee.',
            },
            {
            question: 'What Is The Neighborhood Like?',
            answer: 'South San Francisco, often referred to as "South City," is renowned for its biotech industry, earning the nickname "The Birthplace of Biotechnology." The city offers a mix of residential and commercial areas, providing a suburban feel with easy access to San Francisco and nearby Silicon Valley, making it attractive for both professionals and families.',
            },
            {
            question: 'What is the parking situation for this property?',
            answer: 'There are 3 assigned parking space for this unit.',
            },
            {
            question: 'What are the heating and cooling systems?',
            answer: 'The property is equipped with electric wall heaters, and no cooling system is present. The heating elements are reported as operational. Due to the absence of a cooling system, buyers may want to consider the installation of air conditioning if desired.',
            },
            {
            question: 'What is the current condition of the property?',
            answer: 'There are no major structural or safety concerns were identified in the summary of the inspection report. Minor wear and damage are noted in various areas such as kitchen countertops and cabinets, and bathroom cabinetry, some interior doors have loose handles and there is some general wear on flooring and some closets lack door guides',
            }
          ],
      },
    }
  },
})
</script>
