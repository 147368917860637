<template>
    <section id="draftanoffer" class="start">
        <div class="container" data-aos="fade-up">
            <header class="section-header">
                <h3>Ready to pull the trigger? Draft an offer now.</h3>
                <h4>You will receive the drafted offer document to review and send to the listing agent within 24 hours.</h4>
            </header>
            <a @click="goTo('/offer')" target="_blank">
            <button target="_blank" rel="noopener noreferrer" class="link-primary">Create an Offer</button>
            </a>
        </div>
    </section>
</template>

<script>
import { goTo } from '../util';

export default {
    methods: {
      goTo: goTo
    }
}
</script>