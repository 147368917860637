<template>
    <section id="typeform" class="values">
        <!-- <div class="container" data-aos="fade-up"> -->
            <!-- <header class="section-header my-0">
                <h3>Ask about the property</h3>
            </header> -->
            <!-- <div class="container"> -->
                <div class="spacer">
                </div>
                <iframe
                    frameborder="0"
                    sandbox="allow-same-origin allow-scripts allow-popups allow-forms"
                    width="100%"
                    height="700"
                    id="inlineFrameExample"
                    title="Inline Frame Example"
                    position="absolute"
                    margin-top="200px"
                    src="https://form.typeform.com/to/rt17dVcY?typeform-embed-id=28266414755272495&amp;typeform-embed=embed-widget&amp;typeform-source=localhost&amp;typeform-medium=snippet&amp;typeform-medium-version=next&amp;embed-opacity=100&amp;typeform-embed-handles-redirect=1">
                </iframe>
        <!-- </div> -->
    </section>
</template>


  <style scoped>
      h1 {
          margin-top: 32px;
          margin-bottom: 32px;
      }
      h4 { 
          margin-top: 32px;
          margin-bottom: 16px;
      }
      section {
            padding: 60px 0;
            padding-bottom: 0;
            overflow: hidden;
        }
  </style>