// Import the functions you need from the SDKs you need
// import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
import firebase from 'firebase/compat/app';
// import { initializeApp } from 'firebase/app';

const firebaseConfig = {
  apiKey: "AIzaSyB9PvvNDJHIHDX3CQCfUfYEbaAPePftzeo",
  authDomain: "heya-ai.firebaseapp.com",
  databaseURL: "https://heya-ai-default-rtdb.firebaseio.com",
  projectId: "heya-ai",
  storageBucket: "heya-ai.appspot.com",
  messagingSenderId: "125294247780",
  appId: "1:125294247780:web:436075f574d042f6e50471",
  measurementId: "G-CJ34T8XPKT"
};

// Initialize Firebase
const firebaseApp = firebase.initializeApp(firebaseConfig);

export default firebaseApp