<template>
    <!-- ======= Contact Section ======= -->
      <section id="contact-listing-agent" class="contact">

        <div class="container" data-aos="fade-up">

          <header class="section-header">
            <p>Contact Listing Agent</p>
          </header>

          <div class="row gy-4">

            <div class="col-lg-6">

              <div class="row gy-4">
                <div class="col-md-6">
                  <div class="info-box">
                    <i class="bi bi-envelope"></i>
                    <h3>Email</h3>
                    <p></p>
                    <a href= "mailto: info@lifebyluxe.com">info@lifebyluxe.com</a>
                    <br />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="info-box">
                    <i class="bi bi-telephone"></i>
                    <h3>Call</h3>
                    <a href= "tell: 1-510-917-1309">+1 (510) 917-1309</a>
                    <br />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="info-box">
                    <i class="bi bi-geo-alt"></i>
                    <h3>Location</h3>
                    <p>4115 Blackhawk Plaza Circle, Dannville, CA 94506</p>
                    <br />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="info-box">
                    <i class="bi bi-clock"></i>
                    <h3>Agent Name</h3>
                    <p>Sante Sandhu - Luxe Real Estate</p>
                    <br />
                  </div>
                </div>
              </div>

            </div>

            <div class="col-lg-6">
              <form class="php-email-form" @submit.prevent="handleSubmit">
                <div class="row gy-4">

                  <div class="col-md-6">
                    <input type="text" name="name" class="form-control" placeholder="Full Name" v-model="name" required>
                  </div>

                  <div class="col-md-6 ">
                    <input type="email" class="form-control" name="email" placeholder="Email" v-model="email" required>
                  </div>

                  <div class="col-md-12">
                    <input type="text" class="form-control" name="subject" placeholder="Subject" v-model="subject" required>
                  </div>

                  <div class="col-md-12">
                    <textarea class="form-control" name="message" rows="6" placeholder="Message" v-model="message" required></textarea>
                  </div>

                  <div class="col-md-12 text-center">
                    <div class="loading">Loading</div>
                    <div class="error-message"></div>
                    <div class="sent-message">Your message has been sent. Thank you!</div>

                    <button type="submit">Send Message</button>
                  </div>

                </div>
              </form>
            </div>
          </div>
        </div>

      </section><!-- End Contact Section -->
</template>

<script>
import '../firebase/config'
import { collection, addDoc, getFirestore } from "firebase/firestore";

export default {
  data() {
    return {
      name: '',
      subject: '',
      email: '',
      message: '',
    }
  },
  methods: {
    async handleSubmit() {
      const utcTime = new Date().toJSON();
      let contactMessage = {
        name: this.name,
        subject: this.subject,
        email: this.email,
        message: this.message,
        timestamp: utcTime
      }
      try {
        // Create a record in Firestore
        const db = getFirestore();
        await addDoc(collection(db, "contacts"), contactMessage);
        this.$toast.success('Thank you for your message. We will get back to you within 1 business day.', {
          // override the global option
          position: 'bottom',
          duration: 9000
        });

        // Sending email notification about the contact.
        // Firebase Cloud Function monitors the emailNotifications column
        // and sends an email when there is a new record.
        // const utcDate = new Date().toJSON().slice(0,10).replace(/-/g,'/');
        // await addDoc(collection(db, "emailNotifications"), {
        //   to: "johnny@upandcoming.ai",
        //   message: {
        //     subject: "Up and Coming Contact Form " + JSON.stringify(utcDate),
        //     text: `Subject: ${contactMessage.subject} \n`
        //           + `From: ${contactMessage.name} \n`
        //           + `Email: ${contactMessage.email} \n`
        //           + `Message: ${contactMessage.message} \n`,
        //   },
        // });
      } catch (e) {
        console.error("Error adding document: ", e);
      }
    }
  }
}
</script>

<style scoped>
    .info-box {
        height: 100%;
    }
    a {
        font-size: 14px;
    }
</style>