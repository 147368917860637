<template>
    <!-- ======= Hero Section ======= -->
    <section id="hero" class="hero d-flex align-items-center">
        <div class="container">
            <div class="row">
                <div class="col-lg-6 d-flex flex-column justify-content-center">
                    <h1 data-aos="fade-up">The future of homebuying is here.</h1>
                    <h2 data-aos="fade-up" data-aos-delay="400">Whether you’re a first-time buyer or looking to find your next home, Heya is your partner in navigating the home buying process with ease and confidence.</h2>
                    <div data-aos="fade-up" data-aos-delay="600">
                    <div class="text-center text-lg-start">
                        <a  v-if='username' @click="goTo('/openhouse/209_castenada')" class="btn-get-started scrollto d-inline-flex align-items-center justify-content-center align-self-center">
                        <span>See beta listing</span>
                        <i class="bi bi-arrow-right"></i>
                        </a>
                        <a v-if='!username' @click="handleLogIn('/openhouse/209_castenada')" class="btn-get-started scrollto d-inline-flex align-items-center justify-content-center align-self-center">
                        <span>See beta listing</span>
                        <i class="bi bi-arrow-right"></i>
                        </a>
                    </div>
                    </div>
                </div>
                <div class="col-lg-6 hero-img" data-aos="zoom-out" data-aos-delay="200">
                    <img src="@/assets/img/hero-1.png" class="img-fluid" alt="">
                </div>
            </div>
        </div>
    </section><!-- End Hero -->
</template>

<script>
import { computed } from "vue";
import { store } from '../store';
import { goTo } from '../util';

export default {
    setup() {
        const username = computed(() => store.username);
        const handleLogIn = computed(() => store.handleLogIn);
        return {
            username,
            handleLogIn
        };
    },
    methods: {
      goTo: goTo
    }
}
</script>