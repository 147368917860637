// https://vuejs.org/guide/scaling-up/state-management.html
// https://stackoverflow.com/questions/65730528/vue-js-navigation-guards-block-every-auth-route-allow-login-to-required-routes

import { reactive, ref } from 'vue'
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import router from '../router'
import LogRocket from 'logrocket';

const username = ref('');
export const store = reactive({
    username,
    handleLogIn: async(route) => {
      // Create an instance of the Google provider object:
      var provider = new firebase.auth.GoogleAuthProvider();
      firebase.auth()
          .signInWithPopup(provider)
          .then((result) => {
              var user = result.user;
              console.log(user);
              username.value = user.email;
              LogRocket.identify('THE_USER_ID_IN_YOUR_APP', {
                name: user.displayName,
                email: user.email,
              });
              router.push(route);
          }).catch((error) => {
              console.log("Google Login Error: " + error.message);
          });
      }
  })