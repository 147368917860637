<template>
    <section id="referencedocuments" class="start my-0 pt-0">
        <div class="container" data-aos="fade-up">
            <header class="section-header">
                <h3>Reference</h3>
                <h4>Heya AI was trained using these disclosure documents and inspection reports.</h4>
                <a v-bind:href="references[listingId]['url']" target="_blank">Disclosure Documents and Reports</a>
            </header>
        </div>
    </section>
</template>
<script>
export default ({
  name: 'ReferenceDocs',
  props: ['listingId'],
  data() {
    return {
      references: {
        "209_castenada": {
            'url': 'https://drive.google.com/drive/folders/1lGl3S1IyVDduBhtnfs6q1o39MBo-lR0B?usp=drive_link',
        },
        "1_mandalay": {
            'url': 'https://drive.google.com/drive/folders/1xgxTeG4nehEptsVze3g7m-AeQI4j_LLt?usp=drive_link',
        },
      },
    }
  },
})
</script>