<template>
    <!-- ======= Start Your Journey Section ======= -->
      <section id="startyourjourney" class="start">

        <div class="container" data-aos="fade-up">

<header class="section-header">
<!-- <h2>Our Values</h2> -->
<h3>Start your journey with Heya today.</h3>
<h4>Discover the smarter way to buy your home.</h4>
</header>
<!-- <a href="https://calendly.com/johnnychien/meet" target="_blank"> -->
<a @click="goTo('/disclosureform')">
  <button target="_blank" rel="noopener noreferrer" class="link-primary">Request disclosure package</button>
</a>
</div>

</section><!-- End Start Your Journey Section -->
</template>

<script>
import { goTo } from '../util'

export default {
    methods: {
      goTo: goTo
    }
}
</script>
