<template>
    <!-- ======= Offer Section ======= -->
      <section id="offer-form" class="contact">

        <div class="container" data-aos="fade-up">

          <header class="section-header">
            <p>Write an Offer</p>
          </header>

          <div class="row gy-4">
            
              <form class="php-email-form" @submit.prevent="handleSubmit">
                <div class="row gy-4">
                  <!-- Address -->
                  <div class="col-md-12">
                    <h5>Property Address</h5>
                    <input type="text" class="form-control" name="address" v-model="address" required>
                  </div>
                  <!-- Purchase price -->
                  <div class="col-md-12">
                    <h5>Purchase Price ({{ formatPrice(price) }})</h5>
                    <input type="text" class="form-control" name="price"
                      v-model="price" required>
                  </div>
                  <!-- Earnest Money -->             
                  <div class="col-md-12">
                    <h5>
                      Deposit <strong class="text-primary">{{depositPercent}}%</strong>
                       as the earnest amount ({{ formatPrice(price * depositPercent/100) }})
                    </h5>
                    <div class="d-flex mb-1">
                      <span class="mt-1">0% </span>
                      <div class="range-field flex-fill mx-3">
                        <input class="w-100 px-0" type="range" min="0" v-bind:max="100" v-model="depositPercent" />
                      </div>
                      <span class="mt-1"> 100%</span>
                    </div>
                  </div>
                  <h3 class="mt-5">Financing Information</h3>
                  <hr class="line-break my-2" />
                  <div class="col-md-12">
                    <h5>
                      Total Down Payment <strong class="text-primary">{{downpaymentPercent}}%</strong>
                      ({{ formatPrice(price * downpaymentPercent/100) }})
                    </h5>
                    <div class="d-flex mb-1">
                      <span class="mt-1">0% </span>
                      <div class="range-field flex-fill mx-3">
                        <input class="w-100 px-0" type="range" min="0" v-bind:max="100" v-model="downpaymentPercent" />
                      </div>
                      <span class="mt-1"> {{100}}%</span>
                    </div>
                  </div>
                  <div class="col-md-12">
                    <h5>Days to Close</h5>
                    <input type="text" name="daysToClose" class="form-control" placeholder="Eg. 45" v-model="daysToClose" required>
                  </div>
                  <!-- Document Uploads -->
                  <!-- <div class="col-md-12">
                    <div>
                      <h5>Proof of Funds</h5>
                      <input class="form-control" type="file" id="formFile">
                    </div>
                  </div>
                  <div class="col-md-12">
                    <div>
                      <h5>Loan Qualification</h5>
                      <input class="form-control" type="file" id="formFile">
                    </div>
                  </div>
                  <div class="col-md-12">
                    <div>
                      <h5>Other Documents</h5>
                      <input class="form-control" type="file" id="formFile">
                    </div>
                  </div> -->

                  <div class="col-md-12">
                    <h5>Days Before Offer Expiration</h5>
                    <input type="text" name="offerExp" class="form-control" placeholder="Eg. 3" v-model="offerExp" required>
                  </div>
                  <div class="col-md-6">
                    <h5>Loan Type</h5>
                    <select class="form-select" name="loanType" v-model="loanType">
                      <option value="conventional">Conventional</option>
                      <option value="fha">FHA</option>
                      <option value="va">VA</option>
                      <option value="seller-financing">Seller Financing</option>
                      <option value="other">Other</option>
                    </select>
                  </div>
                  <div class="col-md-6">
                    <h5>Occupancy Type</h5>
                    <select class="form-select" name="occupancyType" v-model="occupancyType">
                      <option value="primary">Primary</option>
                      <option value="secondary">Secondary</option>
                      <option value="investment">Investment</option>
                    </select>
                  </div>
                  <div class="col-md-6">
                    <h5>Liquidated Damages</h5>
                    <select class="form-select" name="liquidatedDamages" v-model="liquidatedDamages">
                      <option value="yes">Yes</option>
                      <option value="no">No</option>
                    </select>
                  </div>
                  <div class="col-md-6">
                    <h5>Arbitration of Disputes</h5>
                    <select class="form-select" name="arbitrationDisputes" v-model="arbitrationDisputes">
                      <option value="yes">Yes</option>
                      <option value="no">No</option>
                    </select>
                  </div>

                  <!-- Contingencies -->
                  <h3 class="mt-5">Contingencies</h3>
                  <hr class="line-break my-2" />
                  <div class="col-md-3">
                    <input class="form-check-input contingency-checkbox" type="checkbox" true-value="1" false-value="0" id="loanContingency" v-model="loanContingency" checked>
                    <label class="form-check-label" for="loanContingency">
                      Loan Contingency
                    </label>
                  </div>
                  <div class="col-md-3">
                    <input class="form-check-input contingency-checkbox" type="checkbox" true-value="1" false-value="0" id="appraisalContingency" v-model="appraisalContingency" checked>
                    <label class="form-check-label" for="loanContingency">
                      Appraisal Contingency
                    </label>
                  </div>
                  <div class="col-md-3">
                    <input class="form-check-input contingency-checkbox" type="checkbox" true-value="1" false-value="0" id="investigationContingency" v-model="investigationContingency" checked>
                    <label class="form-check-label" for="loanContingency">
                      Investigation Contingency
                    </label>
                  </div>
                  <div class="col-md-3">
                    <input class="form-check-input contingency-checkbox" type="checkbox" true-value="1" false-value="0" id="sellerDocsContingency" v-model="sellerDocsContingency"  checked>
                    <label class="form-check-label" for="loanContingency">
                      Seller Documents Review
                    </label>
                  </div>
                  <div class="col-md-3">
                    <input class="form-check-input contingency-checkbox" type="checkbox" true-value="1" false-value="0" id="titleReportContingency" v-model="titleReportContingency" checked>
                    <label class="form-check-label" for="loanContingency">
                      Title Report Review
                    </label>
                  </div>
                  <div class="col-md-3">
                    <input class="form-check-input contingency-checkbox" type="checkbox" true-value="1" false-value="0" id="homeSaleContingency" v-model="homeSaleContingency" checked>
                    <label class="form-check-label" for="loanContingency">
                      Home Sale Contingency
                    </label>
                  </div>

                  <!-- Buyer Information -->
                  <h3 class="mt-5">Buyer Information</h3>
                  <hr class="line-break my-2" />
                  
                  <div class="col-md-12">
                    <h5>Buyer Full Name</h5>
                    <input type="text" name="name" class="form-control" placeholder="Full Name" v-model="buyerFullName" required>
                  </div>

                  <div class="col-md-12 ">
                    <h5>Email</h5>
                    <input type="email" class="form-control" name="email" placeholder="Email" v-model="email" required>
                  </div>
                  
                  <div class="col-md-12">
                    <h5>Phone</h5>
                    <input type="text" class="form-control" name="phone" placeholder="Phone" v-model="phone" required>
                  </div>

                  <div class="col-md-12 text-center">
                    <div class="loading">Loading</div>
                    <div class="error-message"></div>
                    <div class="sent-message">We will email you the draft of your offer shortly. Thank you!</div>
                    <button type="submit">Create</button>
                  </div>

                </div>
              </form>
            
          </div>
        </div>

      </section>
      <!-- End Contact Section -->
</template>

<script>
import firebaseApp from '../firebase/config';
import { getFirestore, doc, setDoc } from "firebase/firestore";

export default {
  data() {
    return {
      price: 0,
      depositPercent: 3,
      downpaymentPercent: 20,
      address: '',
      buyerFullName: '',
      email: '',
      phone: '',
      daysToClose: '',
      offerExp: '',
      fundProofDoc: null,
      preapprovalDoc: null,
      otherDoc: null,
      loanType: '',
      occupancyType: '',
      liquidatedDamages: '',
      arbitrationDisputes: '',
      loanContingency: 1,
      appraisalContingency: 1,
      investigationContingency: 1,
      sellerDocsContingency: 1,
      titleReportContingency: 1,
      homeSaleContingency: 1,
    }
  },
  methods: {
    formatPrice: (numb) => {
      return '$' + Intl.NumberFormat().format(numb);
    },
    async handleSubmit() {
      const utcTime = new Date().toJSON();
      let offer = {
        price: this.price,
        depositPercent: this.depositPercent,
        downpaymentPercent: this.downpaymentPercent,
        address: this.address,
        buyerFullName: this.buyerFullName,
        email: this.email,
        phone: this.phone,
        daysToClose: this.daysToClose,
        offerExp: this.offerExp,
        fundProofDoc: this.fundProofDoc,
        preapprovalDoc: this.preapprovalDoc,
        otherDoc: this.otherDoc,
        loanType: this.loanType,
        occupancyType: this.occupancyType,
        liquidatedDamages: this.liquidatedDamages,
        arbitrationDisputes: this.arbitrationDisputes,
        loanContingency: this.loanContingency,
        appraisalContingency: this.appraisalContingency,
        investigationContingency: this.investigationContingency,
        sellerDocsContingency: this.sellerDocsContingency,
        titleReportContingency: this.titleReportContingency,
        homeSaleContingency: this.homeSaleContingency,
        timestamp: utcTime
      }
      console.log("Offer values: " + JSON.stringify(offer))
      try {
        // Create a record in Firestore
        const db = getFirestore(firebaseApp);
        await setDoc(doc(db, "offers",`${offer.address}-${offer.timestamp}`), offer);
        this.$toast.success('Thank you for your message. We will get back to you within 1 business day.', {
          // override the global option
          position: 'bottom',
          duration: 9000
        });
      } catch (e) {
        console.error("Error adding document: ", e);
      }
    }
  },
}
</script>

<style scoped>
    .info-box {
        height: 100%;
    }
    a {
        font-size: 14px;
    }
    .contingency-checkbox {
      margin-right: 8px;
    }
</style>