<template>
    <div class="Forbidden">
      <section id="Forbidden" class="align-items-center">
          <div class="container">
              <h1>403 Forbidden</h1>
          </div>
      </section>
    </div>
  </template>
  
  <style scoped>
      h1 {
          margin-top: 128px;
          margin-bottom: 128px;
      }
  </style>