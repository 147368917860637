<template>
    <!-- ======= Header ======= -->
    <header id="header" class="header fixed-top">
      <div class="container-fluid container-xl d-flex align-items-center justify-content-between">

        <a @click="goTo('/')" class="logo d-flex align-items-center">
          <img src="@/assets/img/heya-logo.png" alt="">
          <span>Heya AI</span>
        </a>        
        <nav id="navbar" class="navbar">
          <ul>
            <li><a class="nav-link active" @click="goTo('/')">Home</a></li>
            <li v-if='username'><a class="getstarted scrollto" @click="goTo('/openhouse/209_castenada')">See beta listing</a></li>
            <li v-if='!username'><a class="getstarted" @click="handleLogIn('/openhouse/209_castenada')">See beta listing</a></li>
          </ul>
          <i class="bi bi-list mobile-nav-toggle"></i>
        </nav>
      </div>
    </header>
</template>

<script>
import { computed } from "vue";
import { store } from '../store';
import { goTo } from '../util';
export default {
    setup() {
      const username = computed(() => store.username);
      const handleLogIn = computed(() => store.handleLogIn);
      return {
          username,
          handleLogIn
      };
    },
    methods: {
      goTo: goTo
    }
}
</script>

<style scoped>
  .navbar-mobile {
    position: fixed;
    overflow: hidden;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background: rgba(1, 22, 61, 0.9);
    transition: 0.3s;
  }
  .header .logo img {
    max-height: 20px;
  }
</style>