<template>
    <section id="portfolio-details" class="portfolio-details">
      <div class="container">
          <template v-if="windowWidth <= 1024">
            <div class="row gy-4"> <!-- Pictures -->
            <div class="col-lg-12">
              <div class="portfolio-details-slider swiper">
                <div class="swiper-wrapper align-items-center">
                  <div class="swiper-slide" v-for="(photo, index) in listing.photos" :key="index">
                    <a v-bind:href="photo" data-gallery="portfolioGallery" class="portfokio-lightbox">
                      <img v-bind:src="photo" alt="">
                    </a>
                  </div>
                </div>
                <div class="swiper-pagination"></div>
              </div> 
            </div>
            </div>
          </template>
          <template v-if="windowWidth > 1024">
          <div class="row gy-4">
            <div class="col-md-6">
              <a v-bind:href="listing.photos[0]" data-gallery="portfolioGallery" class="portfokio-lightbox">
                <img v-bind:src="listing.photos[0]" class="img-fluid" alt="">
              </a>
              
            </div>
            <div class="col-md-3">
              <div class="row mb-3">
                <a v-bind:href="listing.photos[1]" data-gallery="portfolioGallery" class="portfokio-lightbox">
                  <img v-bind:src="listing.photos[1]" class="img-fluid" alt="">
                </a>
              </div>
              <div class="row">
                <a v-bind:href="listing.photos[2]" data-gallery="portfolioGallery" class="portfokio-lightbox">
                  <img v-bind:src="listing.photos[2]" class="img-fluid" alt="">
                </a>
              </div>
            </div>
            <div class="col-md-3">
              <div class="row mb-3">
                <a v-bind:href="listing.photos[3]" data-gallery="portfolioGallery" class="portfokio-lightbox">
                  <img v-bind:src="listing.photos[3]" class="img-fluid" alt="">
                </a>
              </div>
              <div class="row">
                <a v-bind:href="listing.photos[4]" data-gallery="portfolioGallery" class="portfokio-lightbox">
                  <img v-bind:src="listing.photos[4]" class="img-fluid" alt="">
                </a>
              </div>
            </div>
          </div>
          <div class="swiper-wrapper d-none">
            <div class="swiper-slide" v-for="(photo, index) in listing.photos.slice(5)" :key="index+5">
              <a v-bind:href="photo" data-gallery="portfolioGallery" class="portfokio-lightbox">
                <img v-bind:src="photo" alt="">
              </a>
            </div>
          </div>
          </template>

        <div class="flex-column flex-md-row">
            <div class="portfolio-description">
                <h2 class="mb-2">{{shortenPrice(listing.price, 2)}} | {{numbWithComma(listing.size)}} Sqft | {{listing.location}}</h2>
                <p>{{listing.beds}} Beds ∙ {{listing.baths}} Baths ∙ {{ listing.address }}  ∙ <a v-bind:href="listing.zillow"  target="_blank">Zillow</a></p>
            </div> <!-- End of Column 1 of Home Info -->
            <hr class="line-break my-5" />
            <!-- Description Sections -->
            <div class="mt-5" v-for="(description, index) in listing.descriptions" :key="index">
              <h5 v-if="description.title"><strong>{{description.title}}</strong></h5>
              <p class="my-3">
                {{description.content}}
              </p>
              <img v-if="description.photo" v-bind:src="description.photo" alt="">
            </div>
        </div>
        <Chatbot class="my-0" v-bind:listingId="listing.id"/>
        <!-- <Contact /> -->
        <ReferenceDocuments class="my-0" v-bind:listingId="listing.id" />
        <Faq class="my-0" v-bind:listingId="listing.id" />
        <DraftAnOffer class="my-0" />
      </div>
    </section><!-- End Portfolio Details Section -->
</template>

<script>
// Import static assets
import Swiper from '@/assets/vendor/swiper/swiper-bundle.min.js';
import Isotope from '@/assets/vendor/isotope-layout/isotope.pkgd.min.js';
import AOS from '@/assets/vendor/aos/aos.js';
import GLightbox from '@/assets/vendor/glightbox/js/glightbox.min.js';
import GridPhotos from '@/components/GridPhotos.vue';
import Faq from '@/components/ListingFaq.vue';
import Contact from '@/components/ContactListingAgent.vue';
import DraftAnOffer from '@/components/DraftAnOffer.vue';
import ReferenceDocuments from '@/components/ReferenceDocuments.vue';
import Chatbot from '@/components/Chatbot.vue';

export default {
  components: {
    Contact,
    Faq,
    GridPhotos,
    ReferenceDocuments,
    DraftAnOffer,
    Chatbot,
  },
  data() {
    return {
      windowWidth: window.innerWidth,
      listings: {
        "209_castenada": {
          id: "209_castenada",
          price: 2598000,   
          beds: '3',
          baths: '2',
          size: '1940',
          location: 'Millbrae, CA',
          descriptions: [
            {
              title: "What's special",
              content: "Meticulously remodeled residence in highly sought-after Mills Estates. This home epitomizes sophistication and comfort. Offering an open floor plan with 3 generously sized bedrooms and 2 baths within 1,940 sq ft of living space, set on an expansive 19,602 sq ft lot. Upon entry, the foyer opens to a spacious living room adorned with a fireplace and captivating views. A formal family room, combined with a dining and kitchen area is enhanced by natural light showcasing the kitchen's custom cabinetry and high-end appliances. The primary suite offers direct access to the backyard that features a Solar heated pool and patio perfect for indoor and outdoor living. This home promises privacy and tranquility, creating an ideal retreat. Conveniently located near shopping, transportation, and top-rated schools.",
            },
          ],
          address: '209 Castenada Dr, Millbrae, CA 94030',
          redfin: 'https://www.redfin.com/CA/Millbrae/209-Castenada-Dr-94030/home/671555',
          zillow: 'https://www.zillow.com/homedetails/209-Castenada-Dr-Millbrae-CA-94030/15510530_zpid/',
          google_map: 'https://goo.gl/maps/gcZ962zp3REKgkHw6',
          photos: [
            require('@/assets/img/milbrae/1.jpg'),
            require('@/assets/img/milbrae/2.jpg'),
            require('@/assets/img/milbrae/3.jpg'),
            require('@/assets/img/milbrae/4.jpg'),
            require('@/assets/img/milbrae/5.jpg'),
            require('@/assets/img/milbrae/6.jpg'),
            require('@/assets/img/milbrae/7.jpg'),
            require('@/assets/img/milbrae/8.jpg'),
            require('@/assets/img/milbrae/9.jpg'),
          ],
        },
        "1_mandalay": {
          id: "1_mandalay",
          price: 899000,   
          beds: '2',
          baths: '2',
          size: '1440',
          location: 'South San Francisco, CA',
          descriptions: [
            {
              title: "What's special",
              content: "Discover luxury living in the newly renovated Mandalay Tower penthouse, featuring 1,440 sq ft of sophisticated space with 10-foot ceilings and serene greenbelt views. This exquisite residence boasts a modern kitchen with stone countertops, stainless steel appliances, and a light-filled ambiance enhanced by updated flooring and fresh paint. Conveniently located near key hubs like Biotech, Grand Avenue, and SFO Aviation, with premium amenities such as three dedicated parking stalls, secure storage, a fitness studio, and easy access to major highways, this home is the epitome of upscale urban living.",
            },
          ],
          address: '1 Mandalay Pl #1610, South San Francisco, CA 94080',
          redfin: 'https://www.redfin.com/CA/South-San-Francisco/1-Mandalay-Pl-94080/unit-1610/home/1588671',
          zillow: 'https://www.zillow.com/homedetails/1-Mandalay-Pl-UNIT-1610-South-San-Francisco-CA-94080/64719918_zpid/',
          google_map: 'https://maps.app.goo.gl/us9q2S3dvb4s5pLQ7',
          photos: [
            require('@/assets/img/1_mandalay/1.jpg'),
            require('@/assets/img/1_mandalay/2.jpeg'),
            require('@/assets/img/1_mandalay/3.jpeg'),
            require('@/assets/img/1_mandalay/4.jpeg'),
            require('@/assets/img/1_mandalay/5.jpeg'),
            require('@/assets/img/1_mandalay/6.jpeg'),
            require('@/assets/img/1_mandalay/7.jpeg'),
            require('@/assets/img/1_mandalay/8.jpeg'),
            require('@/assets/img/1_mandalay/9.jpeg'),
          ],
        },
      },
    }
  },
  computed: {
    listing: function() {
        if (this.$route.params.id in this.listings) {
          return this.listings[this.$route.params.id];
        }
        // default to beta listing for now. This should be 404 instead.
        return this.listings['209_castenada'];
    },
  },
  mounted () {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize);
    });
    this.run();
  },
  beforeDestroy() { 
    window.removeEventListener('resize', this.onResize); 
  },
  methods: {
    onResize() {
      this.windowWidth = window.innerWidth
    },
    formatPrice: (numb, decimal) => {
      return '$' + Intl.NumberFormat().format(numb.toFixed(decimal)) + ' USD';
    },
    numbWithComma: (num) => {
      return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    },
    shortenPrice: (num, digits) => {
      const lookup = [
        { value: 1, symbol: "" },
        { value: 1e3, symbol: "k" },
        { value: 1e6, symbol: "M" },
        { value: 1e9, symbol: "B" },
      ];
      const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
      var item = lookup.slice().reverse().find(function(item) {
        return num >= item.value;
      });
      return item ? '$'+(num / item.value).toFixed(digits).replace(rx, "$1") + item.symbol + " USD": "0";
    },
    run: () => {
      "use strict";

      /**
       * Easy selector helper function
       */
      const select = (el, all = false) => {
        el = el.trim()
        if (all) {
          return [...document.querySelectorAll(el)]
        } else {
          return document.querySelector(el)
        }
      }

      /**
       * Easy event listener function
       */
      const on = (type, el, listener, all = false) => {
        if (all) {
          select(el, all).forEach(e => e.addEventListener(type, listener))
        } else {
          select(el, all).addEventListener(type, listener)
        }
      }

      /**
       * Portfolio details slider
       */
      new Swiper('.portfolio-details-slider', {
        speed: 400,
        autoplay: {
          delay: 5000,
          disableOnInteraction: false
        },
        pagination: {
          el: '.swiper-pagination',
          type: 'bullets',
          clickable: true
        }
      });

      /**
       * Porfolio isotope and filter
       */
      window.addEventListener('load', () => {
        let portfolioContainer = select('.portfolio-container');
        if (portfolioContainer) {
          let portfolioIsotope = new Isotope(portfolioContainer, {
            itemSelector: '.portfolio-item',
            layoutMode: 'fitRows'
          });

          let portfolioFilters = select('#portfolio-flters li', true);

          on('click', '#portfolio-flters li', function(e) {
            e.preventDefault();
            portfolioFilters.forEach(function(el) {
              el.classList.remove('filter-active');
            });
            this.classList.add('filter-active');

            portfolioIsotope.arrange({
              filter: this.getAttribute('data-filter')
            });
            aos_init();
          }, true);
        }

      });

      /**
       * Initiate portfolio lightbox 
       */
      const portfolioLightbox = GLightbox({
        selector: '.portfokio-lightbox'
      });

      // const lightbox = GLightbox({ ...options });

      /**
       * Animation on scroll
       */
      function aos_init() {
        AOS.init({
          duration: 1000,
          easing: "ease-in-out",
          once: true,
          mirror: false
        });
      }
      window.addEventListener('load', () => {
        aos_init();
      });
    }
  }
}
</script>

<style scoped>

  section {
    margin: 64px 0 64px 0;
  }
  
  .line-items {
    font-weight: 300;
  }

  .total {
    font-weight: bold;
  }

  .portfolio-description {
    margin-right: 16px;
  }
  #price-cal-side {
    height: 50%;
    width: 40%;
  }
</style>
