<template>
    <section id="chatbot" class="start my-0 pb-0">
        <div class="container my-0 py-0" data-aos="fade-up">
            <header class="section-header my-0">
                <h3>Ask about the property</h3>
            </header>
            <!-- <div class="container"> -->
                <iframe
                    frameborder="0"
                    width="100%"
                    height="900"
                    id="inlineFrameExample"
                    title="Inline Frame Example"
                    v-bind:src="'https://heya-ai.onrender.com?listing=' + listingId + '&__theme=light'" >
                </iframe>
        </div>
    </section>
</template>

<script>
export default {
  name: 'Chatbot',
  props: ['listingId']
}   
</script> 