import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import VueToast from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-default.css';
// import VueAnalytics from 'vue-analytics';
import  { store } from './store/index.js';
import LogRocket from 'logrocket';

// Initialize LogRocket
LogRocket.init('hxvfzz/heya-app');

const app = createApp(App);
// app.use(VueAnalytics, {
//     id: 'G-M07NQXG1XZ',
//     router
// });
app.use(router);
app.use(store);
app.use(VueToast); // Use this.$toast.open('Success!');
app.mount('#app');