import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import Offer from '../views/Offer.vue'
import TermsOfService from '../views/TermsOfService.vue'
import ListingDetail from '../views/ListingDetail.vue'
import OpenHouse from '../views/OpenHouse.vue'
import NotFound from '../views/NotFound.vue'
import DisclosureRequest from '../views/DisclosureRequest.vue'
import Forbidden from '../views/Forbidden.vue'
import { store } from '../store';

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: { requiresAuth: false }
  },
  {
    path: '/offer',
    name: 'Offer',
    component: Offer,
    meta: { requiresAuth: false }
  },
  {
    path: '/terms',
    name: 'TermsOfService',
    component: TermsOfService,
    meta: { requiresAuth: false }
  },
  { path: '/listings/:id',
    name: 'ListingDetail',
    component: ListingDetail,
    meta: { requiresAuth: false }
  },
  { path: '/openhouse/:id',
    name: 'OpenHouse',
    component: OpenHouse,
    meta: { requiresAuth: true }
  },
  { path: '/beta/:id',
    name: 'Beta',
    component: OpenHouse,
    meta: { requiresAuth: false }
  },
  { path: '/disclosureform',
    name: 'DisclosureRequest',
    component: DisclosureRequest,
    meta: { requiresAuth: false }
  },
  { path: '/404',
    component: NotFound,
    meta: { requiresAuth: false }
  },
  { path: '/403',
    component: Forbidden,
    meta: { requiresAuth: false }
  },  
  { 
    path: "/:catchAll(.*)", // Unrecognized path automatically matches 404
    redirect: '/404'
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  if (to.meta.requiresAuth && !store.username) {
      next('/403');
  } else {
      next()
  }
})

export default router
